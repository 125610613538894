 * {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
 }

 body{
    background: linear-gradient(
        90deg,
        rgb(234, 6, 6) 0%,
        rgb(245, 225, 7) 100%
    );

 }

 .navbar {
  width: 100%;
  height: 12vh;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 10px;
  font-family:Georgia, 'Times New Roman', Times, serif;
  font-size: 30px;
 }

.product-container{
    width: 100%;
    min-height: 80vh;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    padding: 50px;
}

.img {
    position: relative;
    transition: all 0.3s ease;
}

img:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 0;
}

.card-description {
    display: flex;
    align-items: center;
    flex-direction: column;
}


.footer-container>hr {
    border: 2px solid ;
    color: rgb(248, 247, 247);
}

.social-links {
    display: flex;
    gap: 5rem;
    height: 1rem;
    padding: -inherit;
}

.social-links>img {
    width: 3rem;
    height: 3rem;
    cursor: pointer;
}


/* Actuall FOOTER */
.footer{
    position: absolute;
    width: 100%;
    height: 6rem;
    background: linear-gradient(90deg,
                rgb(238, 6, 6) 0%,
                rgb(237, 218, 7) 100%);
    /* z-index: -1; */
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    padding: 1rem 5rem;
}


/* Making RESPONSIVNESS */
@media only screen and (max-width: 600px) {
    .product-container {
        grid-template-columns: repeat(2, 1fr);
    }
         .navbar {
             height: 8vh;
             font-size: 16px;
         }

                 .footer {
                     height: 20vh;
                     font-size: 16px;
                 }
                                 .card {
                                     margin: 10px 0;
                                     font-size: 16px;
                                 }
}

@media only screen and (max-width: 400px) {
    .product-container {
        grid-template-columns: repeat(1, 1fr);
    }
         .navbar {
             height: 8vh;
             font-size: 14px;
         }

                  .footer {
                      height: 5vh;
                      font-size: 14px;
                  }

                                   .card {
                                       margin: 5px 0;
                                       font-size: 14px;
                                   }
   
}

@media only screen and (max-width: 600px) {
    .social-links {
        flex-direction: row ;
        height: 2rem;
        gap: 1rem;
        padding: auto;
    }

}